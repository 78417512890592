import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import About from "../components/about.js"

import { Helmet } from "react-helmet"

export default ({ data }) => {
  const theFooter = data.allWordpressPage.edges.filter(({ node }) => node.slug === "contact")[0].node
  const theHome = data.allWordpressPage.edges.filter(({ node }) => node.slug === "anna-kovner")[0].node

 


  return (
    <Layout footer={theFooter} >
      {/* headerImageData={theHome.acf.background.localFile.childImageSharp.fluid} > */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>Anna Kovner</title>
        <link rel="canonical" href="https://annakovner.com" />
      </Helmet>

      <About value={theHome} />

    </Layout>
  )
}

export const query = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          content
          slug
          title
          acf {
            image {
              
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700, toFormat:PNG) {
                    aspectRatio
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
