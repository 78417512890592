import React from "react"
import Img from "gatsby-image"

const About = ({ value }) => {
  const fluid = value.acf.image.localFile.childImageSharp.fluid


  return (
    <div className="block">
    <div className="about">
     
        <div className="headshot">
          <div className="inner">
            <Img
              
              fluid={fluid}
              objectFit="cover"
               objectPosition="center center"
              alt={value.title}
              fadeIn={true}
              backgroundColor={true}
            
            />
          </div>
        </div>
        <div className="bio">
          <div dangerouslySetInnerHTML={{ __html: value.content }} />
        </div>
      
    </div>
    </div>

  )
}

export default About
